import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper';
import { Card, CardType } from '../ui/card/card';
import './carousel.scss';

export type Carousel = {
  content: CardType[];
  initSwiperSize: string;
}

export const Carousel: React.FC<Carousel> = ({ content, initSwiperSize }) => {
  const isMobile = useMediaQuery({ query: `(max-width: ${initSwiperSize})` });
  const [swiper, setSwiper] = useState(null);

  useEffect(() => {
    if (swiper) {
      isMobile ? swiper.enable() : swiper.disable();
    }
  }, [isMobile, swiper]);

  return (
    <Swiper
      slidesPerView={1}
      spaceBetween={50}
      pagination={{ clickable: true }}
      modules={[Pagination]}
      onSwiper={(sw) => setSwiper(sw)}
    >
      {content.map(({
        title, text, image, imageAlt,
      }, index) => (
        <SwiperSlide key={title}>
          <Card
            text={text}
            title={title}
            image={`../images/${image}`}
            isReversed={Boolean(index % 2)}
            imageAlt={imageAlt}
          />
        </SwiperSlide>
      ))}
    </Swiper>
  );
};
