import React from 'react';
import './arrow.scss';

export const Arrow: React.FC<{onClick: (e: MouseEvent) => void}> = ({ onClick }) => (
  <a className="arrow" href="#content" onClick={onClick}>
    <span> </span>
    <span> </span>
    <span> </span>
  </a>
);
