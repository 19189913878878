import React from 'react';
import { Trans, useTranslation } from 'gatsby-plugin-react-i18next';
import { Button } from '../ui/button/button';
import { Arrow } from '../ui/arrow/arrow';
import { Highlight } from '../ui/highlight/highlight';
import VideoPoster from '../../images/poster-main-video.webp';
import { CardType } from '../ui/card/card';
import { Carousel } from '../carousel/carousel';
import './home.scss';

export const Home: React.FC = () => {
  const { t } = useTranslation();
  const footerSectionContentCarousel: CardType[] = t('pages.home.section.footer.carousel', {
    returnObjects: true,
  });

  const handleClick = (e: MouseEvent) => {
    e.preventDefault();

    const target = (e.target as HTMLAnchorElement).getAttribute('href');
    const location = (document.querySelector(target) as HTMLBodyElement).offsetTop;

    window.scrollTo({
      left: 0,
      top: location,
    });
  };

  return (
    <div className="home">
      <div className="home__header">
        <section className="home__hero">
          <video
            playsInline
            autoPlay
            muted
            loop
            className="home__video-background"
            poster={VideoPoster}
          />
          <div className="home__title">
            <h1>{t('pages.home.title')}</h1>
            <Button
              text={t('buttons.foundations.name')}
              link={t('buttons.foundations.url')}
            />
          </div>
          <Arrow onClick={handleClick} />
        </section>

      </div>
      <section className="home__footer">
        <div className="container--fluid" id="content">
          <div className="home__footer-title">
            <h2 className="h2 regular">
              <Trans
                i18nKey="pages.home.section.footer.title"
                components={{ highlight: <Highlight /> }}
              />
            </h2>
          </div>
          <Carousel content={footerSectionContentCarousel} initSwiperSize="1028px" />
        </div>
      </section>
    </div>
  );
};
