import * as React from 'react';
import { graphql } from 'gatsby';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Layout from '@components/layout/layout';
import { Home } from '@components/home/home';
import { Helmet } from 'react-helmet';

const IndexPage = () => {
  const { t } = useTranslation();
  
  return (
    <Layout className="main__home">
      <Helmet>
        <meta name="keywords" content={t('pages.home.metaKeywords')} />
        <meta name="description" content={t('pages.home.metaDescription')} />
        <title>{t('pages.home.documentTitle')}</title>
      </Helmet>
      <Home />
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`;
